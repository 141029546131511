import './../../App.css';
import Login from '../LoginForm/Login'
import Header from '../Header';
import { Route, BrowserRouter, Switch} from "react-router-dom";
// import DOBGender from './Components/LoginForm/DOBGender'
import Sidebar from '../Sidebar';
import Buttommenu from '../buttommenu'
import React, { Component } from 'react'
// import Cashflow2 from './Components/LoginForm/Cashflow2';
// import Cashflow from './Components/LoginForm/Cashflow';
export class afterlanding extends Component {
    render() {
        setTimeout(() => {
            localStorage.removeItem("halaltoken");
        }, 18000);
        var halaltoken= localStorage.getItem("halaltoken")
        console.log("halaltoken",halaltoken)
        console.log("props",this.props)
        if(this.props.location.data===halaltoken){
            console.log("success")
        }
        else{
            console.log("fail")
            this.props.history.push('/')
            // this.props.history.push('/afterlanding')
     
        }
      return (
        <BrowserRouter >
        <div className="App">
          <Header />
          <br/>
          <Buttommenu/>
          <div className= 'container'>
            <Sidebar/>
            <div className='main-page'>
                <Login/>
              <div  className='main-page-element' id='footid' style={{
              backgroundColor:'white',
              height:'50px',
              marginBottom:0,
              minHeight:'0'
              }}></div>
            </div>
            
          </div>
          
        </div>
        {/* <Switch>
            <Route exact path="/Login" component={Login} />
            <Route exact path="/dobGender" component={DOBGender} />
        </Switch> */}
      </BrowserRouter>
      );
    }
  }
  
  
  export default afterlanding;
  