import React, { Component } from 'react'
import './../../App.css'

export class DOBGender extends Component {
    constructor(props){
        super()
        this.state={
            err_msg:""
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
      }

    btnDisable=true
    day=false
    month=false
    year=false
    // inputStyle={
    //     width:"55px",
    //     padding:"20px",
    //     paddingLeft:"30px",
    //     paddingRight:"30px",
    //     margin:"5px",
    //     fontSize:"20px"
    // }
    changeDay=(e)=>{
        this.props.day(e.target.value)
        if(e.target.value < 1 || e.target.value > 31  || !e.target.value.match(/^[0-9]+$/)  || (e.target.value === "")){
        document.getElementById("day").className = "error";
        this.setState({
            err_msg:"Please provide a valid Date"
            
        })
        this.day=false
     }

        if((e.target.value >0 && e.target.value < 32) ){
        document.getElementById("day").className ="dobinput"
        this.setState({
            err_msg:""
            
        })
        this.day=true
    }
    this.enabledisableBotton()
    }
    changeMonth=(e)=>{
        this.props.month(e.target.value)

        if(e.target.value < 1 || e.target.value > 12 || !e.target.value.match(/^[0-9]+$/)  || (e.target.value === "")){
            document.getElementById("month").className = "error";
            this.setState({
                err_msg:"Please provide a valid Month"
                
            })
            this.month=false
        }
        if((e.target.value > 0 && e.target.value < 13)){
            document.getElementById("month").className ="dobinput"
            this.setState({
                err_msg:""
                
            })
            this.month=true
        }
        this.enabledisableBotton()
    }
    changeYear=(e)=>{
        this.props.year(e.target.value)
        if(e.target.value < 1900 || e.target.value > 2019 || !e.target.value.match(/^[0-9]+$/)  || (e.target.value === "")){

            document.getElementById("year").className = "error";
            this.setState({
                err_msg:"Please provide a valid Year"
                
            })
            this.year=false
        }
        var d = new Date();
        var year = d.getFullYear();
        // console.log("year",year)
            if((e.target.value >1899 && e.target.value <= year)){
            document.getElementById("year").className ="dobinput"
            this.setState({
                err_msg:""
                
            })
            // console.log("year",year)
            this.year=true
        }
        this.enabledisableBotton()
    }

    enabledisableBotton=()=>{
           
        // if (this.state.nameFill && this.state.emailFill)
        if(this.day && this.month && this.year)
        {
        this.btnDisable=false
        }
        
        else
        {
        this.btnDisable=true
        }
        // console.log("day",this.day)
        // console.log("month",this.month)
        // console.log("year",this.year)
        // console.log("btnDisable",this.btnDisable)
    }
    render() {
        // if(this.day && this.month && this.year) 
        // this.btnDisable=false
        // else this.btnDisable=true
        document.getElementById('ageGenderCircle').style.backgroundColor='#F58023'
        document.getElementById('ageGender').style.backgroundColor='#F58023'
        document.getElementById('footid').style.height='79%'
        
        return (
            <div className="main-page-element">
                <h1 className="nameh1">To help you create your personalised, “Halal Financial Plan’,
                    I need to know a little bit more about you.
                </h1>
                <br></br>
                <p className="namep">When were you born?</p>
                <input id="day" className="dobinput" placeholder="DD" onChange={this.changeDay} maxLength ="2"></input>
                <input id="month" className="dobinput" placeholder="MM" onChange={this.changeMonth} maxLength= "2"></input>
                <input id="year" className="dobinput" placeholder="YYYY" onChange={this.changeYear} maxLength="4"></input>
                <br /><br />
                <label style={{color:"red"}}>{this.state.err_msg}</label>
                <br></br>
                {/* <p style={{fontWeight:"bold"}}>And what is your gender</p>
                <div>
                    <label>
                        <input type="radio" name="gender" value="male" 
                            onChange={this.changeGender}/> 
                        < img style={{height:"130px"}} src={require('./male.png')} alt='male'/>
                    </label>&emsp;
                    <label>
                        <input type="radio" name="gender" value="female" 
                        onChange={this.changeGender}/>
                        < img style={{height:"130px"}} src={require('./female.png')} alt='female'/>
                    </label>
                </div>
                <p>male &emsp;&emsp;&emsp;&emsp;&emsp;&emsp; female </p>
                <br /> */}
                
                <button onClick={this.props.next} disabled={this.btnDisable}>Next</button>
                
            </div>
        )
    }
}

export default DOBGender

