import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'

class Cashflow2 extends Component {
    constructor(props){
        super(props)
        this.state={
            cash_bank_deposites:0,
            cash_others:0,
            total_cash_near_cash:0,
            cpf_ordinary_account:0,
            cpf_special_account:0,
            cpf_medisave_account:0,
            investment:0,
            investment_cash_value:0,
            invested_others:0,
            total_invested_assets:0,
            family_home:0,
            moter_vehicles:0,
            total_personal_use_assets:0,
            total_assests:0,
            credit_card_balances:0,
            current_liablilities_others:0,
            car_hire_purchase:0,
            mortgage_loan_balance:0,
            long_term_liabilities_others:0,
            
        }
    }

    cash_bank_deposites =(e)=>{
        if(e.target.value)
        this.setState({
            cash_bank_deposites :parseInt(e.target.value)
        })
        else
        this.setState({
            cash_bank_deposites :0
        })
    }
    cash_others =(e)=>{
        if(e.target.value)
        this.setState({
            cash_others :parseInt(e.target.value)
        })
        else
        this.setState({
            cash_others :0
        })
    }

    total_cash_near_cash =(e)=>{
        if(e.target.value)
        this.setState({
            total_cash_near_cash :parseInt(e.target.value)
        })
        else
        this.setState({
            total_cash_near_cash :0
        })
    }

    cpf_ordinary_account =(e)=>{
        if(e.target.value)
        this.setState({
            cpf_ordinary_account :parseInt(e.target.value)
        })
        else
        this.setState({
            cpf_ordinary_account :0
        })
    }

    cpf_special_account =(e)=>{
        if(e.target.value)
        this.setState({
            cpf_special_account :parseInt(e.target.value)
        })
        else
        this.setState({
            cpf_special_account :0
        })
    }

    cpf_medisave_account =(e)=>{
        if(e.target.value)
        this.setState({
            cpf_medisave_account :parseInt(e.target.value)
        })
        else
        this.setState({
            cpf_medisave_account :0
        })
    }

    investment =(e)=>{
        if(e.target.value)
        this.setState({
            investment :parseInt(e.target.value)
        })
        else
        this.setState({
            investment :0
        })
    }
    
    investment_cash_value =(e)=>{
        if(e.target.value)
        this.setState({
            investment_cash_value :parseInt(e.target.value)
        })
        else
        this.setState({
            investment_cash_value :0
        })
    }

    invested_others=(e)=>{
        if(e.target.value)
        this.setState({
            invested_others :parseInt(e.target.value)
        })
        else
        this.setState({
            invested_others :0
        })
    }

    total_invested_assets =(e)=>{
        if(e.target.value)
        this.setState({
            total_invested_assets :parseInt(e.target.value)
        })
        else
        this.setState({
            total_invested_assets :0
        })
    }

    family_home =(e)=>{
        if(e.target.value)
        this.setState({
            family_home :parseInt(e.target.value)
        })
        else
        this.setState({
            family_home :0
        })
    }

    moter_vehicles =(e)=>{
        if(e.target.value)
        this.setState({
            moter_vehicles :parseInt(e.target.value)
        })
        else
        this.setState({
            moter_vehicles :0
        })
    }

    total_personal_use_assets =(e)=>{
        if(e.target.value)
        this.setState({
            total_personal_use_assets :parseInt(e.target.value)
        })
        else
        this.setState({
            total_personal_use_assets :0
        })
    }

    total_assests =(e)=>{
        if(e.target.value)
        this.setState({
            total_assests :parseInt(e.target.value)
        })
        else
        this.setState({
            total_assests :0
        })
    }

    credit_card_balances =(e)=>{
        if(e.target.value)
        this.setState({
            credit_card_balances :parseInt(e.target.value)
        })
        else
        this.setState({
            credit_card_balances :0
        })
    }

    current_liablilities_others =(e)=>{
        if(e.target.value)
        this.setState({
            current_liablilities_others :parseInt(e.target.value)
        })
        else
        this.setState({
            current_liablilities_others :0
        })
    }

    car_hire_purchase =(e)=>{
        if(e.target.value)
        this.setState({
            car_hire_purchase :parseInt(e.target.value)
        })
        else
        this.setState({
            car_hire_purchase :0
        })
    }

    mortgage_loan_balance =(e)=>{
        if(e.target.value)
        this.setState({
            mortgage_loan_balance :parseInt(e.target.value)
        })
        else
        this.setState({
            mortgage_loan_balance :0
        })
    }

    long_term_liabilities_others =(e)=>{
        if(e.target.value)
        this.setState({
            long_term_liabilities_others :parseInt(e.target.value)
        })
        else
        this.setState({
            long_term_liabilities_others :0
        })
    }

    componentDidMount(){
        window.scrollTo(0,0)
    }
    handleClick=()=>{
        this.props.next()
        document.getElementById('cashCircle').style.backgroundColor='#F58023'
        document.getElementById('aboutyouCircle').style.backgroundColor='#F58023'
        let assets={
            cash_near_cash:{
                cash_bank_deposites:this.state.cash_bank_deposites,
                cash_others:this.state.cash_others,
                total_cash_near_cash: this.state.cash_bank_deposites
                +this.state.cash_others
            },
            invested_assets:{
                cpf_ordinary_account:this.state.cpf_ordinary_account,
                cpf_special_account:this.state.cpf_special_account,
                cpf_medisave_account:this.state.cpf_medisave_account,
                investment:this.state.investment,
                investment_cash_value:this.state.investment_cash_value,
                invested_others:this.state.invested_others,
                total_invested_assets:this.state.cpf_ordinary_account+
                                    this.state.cpf_special_account+
                                    this.state.cpf_medisave_account+
                                    this.state.investment+
                                    this.state.investment_cash_value+
                                    this.state.invested_others
            },
            personal_use_assets:{
                family_home:this.state.family_home,
                moter_vehicles:this.state.moter_vehicles,
                total_personal_use_assets: this.state.family_home+
                this.state.moter_vehicles
            },
            total_assests:this.state.cash_bank_deposites+
                        this.state.cash_others+
                        this.state.cpf_ordinary_account+
                        this.state.cpf_special_account+
                        this.state.cpf_medisave_account+
                        this.state.investment+
                        this.state.investment_cash_value+
                        this.state.invested_others+
                        this.state.family_home+
                        this.state.moter_vehicles
        }
        let liabilities={
            current_liabilities:{
                credit_card_balances:this.state.credit_card_balances,
                current_liablilities_others:this.state.current_liablilities_others,
                total_current_liabilities:this.state.credit_card_balances+
                                        this.state.current_liablilities_others
            },
            long_term_liabilities:{
                car_hire_purchase:this.state.car_hire_purchase,
                mortgage_loan_balance:this.state.mortgage_loan_balance,
                long_term_liabilities_others:this.state.long_term_liabilities_others,
                total_long_liabilities:this.state.car_hire_purchase+
                                    this.state.mortgage_loan_balance+
                                    this.state.long_term_liabilities_others
            },
            total_liabilities:this.state.credit_card_balances+
                                this.state.current_liablilities_others+
                                this.state.car_hire_purchase+
                                this.state.mortgage_loan_balance+
                                this.state.long_term_liabilities_others
        }
        let networth={
            networth:(this.state.cash_bank_deposites+
                this.state.cash_others+
                this.state.cpf_ordinary_account+
                this.state.cpf_special_account+
                this.state.cpf_medisave_account+
                this.state.investment+
                this.state.investment_cash_value+
                this.state.invested_others+
                this.state.family_home+
                this.state.moter_vehicles)-
                (this.state.credit_card_balances+
                    this.state.current_liablilities_others+
                    this.state.car_hire_purchase+
                    this.state.mortgage_loan_balance+
                    this.state.long_term_liabilities_others),
            total_networth_and_liabilities:this.state.cash_bank_deposites+
                                        this.state.cash_others+
                                        this.state.cpf_ordinary_account+
                                        this.state.cpf_special_account+
                                        this.state.cpf_medisave_account+
                                        this.state.investment+
                                        this.state.investment_cash_value+
                                        this.state.invested_others+
                                        this.state.family_home+
                                        this.state.moter_vehicles
        }
        this.props.assets(assets)
        this.props.liabilities(liabilities)
        this.props.netWorth(networth)
    }
    render(){
        document.getElementById('cash').style.backgroundColor='#F58023'
    return (
        <div className='main-page-elementflow2' >
       
        <div>
        <div className="btnflow2">
        <a href="https://my-schedule.timetrade.com/app/td-2524385/workflows/w8w3h/schedule/welcome?wfsid=16a5bdb5-baba97f6-16a5be82-baba97f6-00000002-5psrhv71kgidilce4l1n5vrj8iraghf6&view=full&fs=1" 
        className="meetlink" target="_blank">
        Meet Helmi Hakim Directly
        </a>
       
        <button  className='informationBtn2' data-tip data-for='currentLiabilitiesInfo1'> <b>i</b>
        <ReactTooltip id='currentLiabilitiesInfo1' type='error'>
          <span className=".meethelmi"> I would like to proceed directly<br/> to meet Helmi Hakim for <br/>“Understand Your Money” session.</span>
        </ReactTooltip></button> </div>
    </div>
            <div className="cashflow2flex">
           <div className="cashFlow2">
                <div className="cashFlow2assetBar">
                    Assets
                </div>
                <div className="cashFlow2div">
                
                    Cash / Near Cash (in $)
                    <button  className='informationBtn' data-tip data-for='cashInfo'> i
                    <ReactTooltip  id='cashInfo' type='error'>
                      <span style={{fontSize:'15px'}}>This refers to your most highly liquid <br/>Halal assets. 
                      You need money, you<br/> can use it anytime you want.</span>
                    </ReactTooltip></button>
                </div>
                
                <div id='cash-elements' className='cashFlow2Elements'>
                    
                    <div className='cashFlow2Element'>
                        <label>Cash / Bank Deposits</label>
                        <input onBlur={this.cash_bank_deposites}></input>
                    </div>
                    
                    <div className='cashFlow2Element'>
                        <label>Others</label>
                        <input onBlur={this.cash_others}></input>
                    </div>
                    <div className='cashFlow2Element'  >
                        <label>Total Cash / Near Cash</label>
                        <span onChange={this.total_cash_near_cash} className="cashflowvalue">
                            {
                                this.state.cash_bank_deposites
                                +this.state.cash_others
                            }
                        </span>
                    </div>
                </div>
                <div className="cashFlow2div">
                    Invested Assets (in $)
                    <button  className='informationBtn' data-tip data-for='investedAssetsInfo'> i
                    <ReactTooltip id='investedAssetsInfo' type='error'>
                      <span style={{fontSize:'15px'}}>You know, if you want to be rich or<br/> financially free,
                       you need to invest <br/>in Halal assets. This kind of assets <br/>work
                        hard for you. Make Halal <br/>money for you through capital<br/>
                         appreciation, dividends, rental<br/> income or Halal profits.</span>
                    </ReactTooltip></button>
                </div>
                <div id='invested_assets-elements' className='cashFlow2Elements'>
                    <div className='cashFlow2Element'>
                        <label>CPF Ordinary Account</label>
                        <input onBlur={this.cpf_ordinary_account}></input>
                    </div>
                    <div className='cashFlow2Element'>
                        <label>CPF Special Account</label>
                        <input onBlur={this.cpf_special_account}></input>
                    </div>
                    <div className='cashFlow2Element'>
                        <label>CPF Medisave Account</label>
                        <input onBlur={this.cpf_medisave_account}></input>
                    </div>
                    <div className='cashFlow2Element'>
                        <label>Investment (e.g. Shares, Sukuk, Unit Trusts, ILP)</label>
                        <input onBlur={this.investment}></input>
                    </div>
                    <div className='cashFlow2Element'>
                        <label>Insurance Cash Value (e.g. traditional policies)</label>
                        <input onBlur={this.investment_cash_value}></input>
                    </div>
                    <div className='cashFlow2Element'>
                        <label>Others</label>
                        <input onBlur={this.invested_others}></input>
                    </div>
                    <div className='cashFlow2Element' >
                        <label>Total Invested Assets</label>
                        <span onChange={this.total_invested_assets} style={{fontWeight:'bold', width:'25%', marginLeft:"7px" }}>
                            {
                                this.state.cpf_ordinary_account+
                                this.state.cpf_special_account+
                                this.state.cpf_medisave_account+
                                this.state.investment+
                                this.state.investment_cash_value+
                                this.state.invested_others
                            }
                        </span>
                    </div>
                    
                </div>
                <div className="cashFlow2div">
                    Personal Use Assets (in $)
                    <button  className='informationBtn' data-tip data-for='personalUseAssetsInfo'> i
                    <ReactTooltip id='personalUseAssetsInfo' type='error'>
                      <span style={{fontSize:'15px'}}>This refers to your assets that take money<br/>
                       out of your pocket every month.<br/> Example your car. You need to pay<br/> for petrol, 
                       parking and maintenance <br/>every month.</span>
                    </ReactTooltip></button>
                </div>
                <div>
                    <div className='cashFlow2Element'>
                        <label>Family Home</label>
                        <input onBlur={this.family_home}></input>
                    </div>
                    <div className='cashFlow2Element'>
                        <label>Motor Vehicles</label>
                        <input onBlur={this.moter_vehicles}></input>
                    </div>
                    <div className='cashFlow2Element'>
                        <label>Total Personal Use Assets</label>
                        <span onChange={this.total_personal_use_assets} style={{fontWeight:'bold', width:'25%',marginLeft:"7px"}}>
                            {
                                this.state.family_home+
                                this.state.moter_vehicles
                            }
                        </span>
                    </div>
                </div>
                <div className='cashFlow2Element'  >
                    <label className="cashflowtotalasset">Total Assets</label>
                    <span onChange={this.total_assests} style={{fontWeight:'bold', width:'25%',fontSize:'20px',marginLeft:"7px"}}>
                            {
                                this.state.cash_bank_deposites+
                                this.state.cash_others+
                                this.state.cpf_ordinary_account+
                                this.state.cpf_special_account+
                                this.state.cpf_medisave_account+
                                this.state.investment+
                                this.state.investment_cash_value+
                                this.state.invested_others+
                                this.state.family_home+
                                this.state.moter_vehicles
                            }
                    </span>
                </div>
                

           </div>
           <div className="cashFlow2">
                <div className="cashFlow2Bar">
                    Liabilities and Net Worth
                </div>
                <div className="cashFlow2div">
                    Current Liabilities (in $)
                    <button  className='informationBtn' data-tip data-for='currentLiabilitiesInfo'> i
                    <ReactTooltip id='currentLiabilitiesInfo' type='error'>
                      <span style={{fontSize:'15px'}}>This refers to your short term financial <br/>obligations/hutang 
                      that you <br/>need to settle within one year.<br/> As soon as possible!</span>
                    </ReactTooltip></button>
                </div>
                <div id='current_liabilities-elements' className='cashFlow2Elements'>
                    <div className='cashFlow2Element'>
                        <label>Credit Card Balances</label>
                        <input onBlur={this.credit_card_balances}></input>
                    </div>
                    <div className='cashFlow2Element'>
                        <label>Others</label>
                        <input onBlur={this.current_liablilities_others}></input>
                    </div>
                    <div className='cashFlow2Element' >
                        <label>Total Current Liabilities</label>
                        <span style={{fontWeight:'bold', width:'25%', marginLeft: "7px"}}>
                            {
                                this.state.credit_card_balances+
                                this.state.current_liablilities_others
                            }
                        </span>
                    </div>
                </div>
                <div className="cashFlow2div">
                    Long Term Liabilities (in $)
                    <button  className='informationBtn' data-tip data-for='longtermLiabilitiesInfo'> i
                    <ReactTooltip id='longtermLiabilitiesInfo' type='error'>
                      <span style={{fontSize:'15px'}}>This refers to your long term financial <br/>obligations/hutang
                       that you can <br/>settle for a longer tenure. <br/>Perhaps 10 years and above. </span>
                    </ReactTooltip></button>
                </div>
                
                <div id='long_term_liabilities-elements' className='cashFlow2Elements'>
                    <div className='cashFlow2Element'>
                        <label>Car Hire Purchase</label>
                        <input onBlur={this.car_hire_purchase}></input>
                    </div>
                    <div className='cashFlow2Element'>
                        <label>Mortgage Loan Balance</label>
                        <input onBlur={this.mortgage_loan_balance}></input>
                    </div>
                    <div className='cashFlow2Element'>
                        <label>Others</label>
                        <input onBlur={this.long_term_liabilities_others}></input>
                    </div>
                    <div className='cashFlow2Element' >
                        <label>Total Long Term Liabilities</label>
                        <span className="cashflowvalue">
                            {
                                this.state.car_hire_purchase+
                                this.state.mortgage_loan_balance+
                                this.state.long_term_liabilities_others
                            }
                        </span>
                    </div>
                </div>
                <br/>
                <div className='cashFlow2Element'>
                    <label style={{fontWeight:'bold',fontSize:'20px'}}>Total Liabilities</label>
                    <span className="cashflowvalue">
                            {
                                this.state.credit_card_balances+
                                this.state.current_liablilities_others+
                                this.state.car_hire_purchase+
                                this.state.mortgage_loan_balance+
                                this.state.long_term_liabilities_others
                            }
                    </span>
                </div>
                
                <br/><br/><br/>
                <div className='cashFlow2Element'>
                    <label style={{fontWeight:'bold',fontSize:'20px'}}>Net Worth</label>
                    <span className="cashflownet">
                            {
                               (this.state.cash_bank_deposites+
                                this.state.cash_others+
                                this.state.cpf_ordinary_account+
                                this.state.cpf_special_account+
                                this.state.cpf_medisave_account+
                                this.state.investment+
                                this.state.investment_cash_value+
                                this.state.invested_others+
                                this.state.family_home+
                                this.state.moter_vehicles)-
                                (this.state.credit_card_balances+
                                    this.state.current_liablilities_others+
                                    this.state.car_hire_purchase+
                                    this.state.mortgage_loan_balance+
                                    this.state.long_term_liabilities_others)
                            }
                    </span>
                </div>
                <br/><br/><br/><br/><br/><br/><br/>
                <div className='cashFlow2Element'>
                    <label className="cashflownet1">Total Liabilities and Net Worth</label>
                    <span className="cashflownet">
                            {
                                this.state.cash_bank_deposites+
                                this.state.cash_others+
                                this.state.cpf_ordinary_account+
                                this.state.cpf_special_account+
                                this.state.cpf_medisave_account+
                                this.state.investment+
                                this.state.investment_cash_value+
                                this.state.invested_others+
                                this.state.family_home+
                                this.state.moter_vehicles
                            }
                    </span>
                </div>
                
           </div>
           </div>
           <br/><br/>
           <div className="cashflownext">
           <button onClick={this.handleClick}>Next</button>
        </div>
        </div>
    )
}
}

export default Cashflow2
