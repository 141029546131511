import React, { Component } from 'react'
import './../../App.css'
import ReactTooltip from 'react-tooltip'

class Cashflow extends Component {
    constructor(props){
        super(props)
        this.state={
            employment_gross:0,
            less_cpf_contribution:0,
            any_other_income:0,
            total_monthly_income:0,
            insurance_premium_saving:0,
            parental_nafkah_support:0,
            housing_loan_installment:0,
            other_loan_installment:0,
            other:0,
            transportation:0,
            entertainment:0,
            food:0,
            miscellaneous:0,
            total_monthly_expenses:0,
            shortfall_surplus:0
        }
    }
    cpfVal=0
    employment_gross=(e)=>{
        // console.log(this.employment_gross)
        if(e.target.value)
        {
            this.setState({
            employment_gross:parseInt(e.target.value)
        })
        
        // console.log("this.state.employment_gross",this.state.employment_gross)
        this.cpfVal=((e.target.value*2)/10)>1200?1200:((e.target.value*2)/10)
        // console.log("cpfVal",this.cpfVal)
        // `${(((this.state.employment_gross*2)/10)>1200?"1200":((this.state.employment_gross*2)/10))}`
       this.state.less_cpf_contribution = document.getElementById('cpf').value=this.cpfVal

    }
        else
        this.setState({
            employment_gross:0
        })
    }
    less_cpf_contribution=(e)=>{
        // console.log("emplyment_gross",this.state.employment_gross)
        var cpf = (this.state.employment_gross*2)/10;
        // console.log("cpf",cpf)
        
        if(e.target.value){
        this.setState({
            less_cpf_contribution:parseInt(e.target.value)
        })
        // console.log("this.state.less_cpf_contribution",this.state.less_cpf_contribution)
    }
        else
        this.setState({
            less_cpf_contribution:0
        })
    }
    any_other_income=(e)=>{
        if(e.target.value)
        this.setState({
            any_other_income:parseInt(e.target.value)
        })
        else
        this.setState({
            any_other_income:0
        })
    }
    insurance_premium_saving=(e)=>{
        if(e.target.value)
        this.setState({
            insurance_premium_saving:parseInt(e.target.value)
        })
        else
        this.setState({
            insurance_premium_saving:0
        })
    }
    parental_nafkah_support=(e)=>{
        if(e.target.value)
        this.setState({
            parental_nafkah_support:parseInt(e.target.value)
        })
        else
        this.setState({
            parental_nafkah_support:0
        })
    }
    housing_loan_installment =(e)=>{
        if(e.target.value)
        this.setState({
            housing_loan_installment:parseInt(e.target.value)
        })
        else
        this.setState({
            housing_loan_installment:0
        })
    }
    other_loan_installment =(e)=>{
        if(e.target.value)
        this.setState({
            other_loan_installment:parseInt(e.target.value)
        })
        else
        this.setState({
            other_loan_installment:0
        })
    }
    other =(e)=>{
        if(e.target.value)
        this.setState({
            other:parseInt(e.target.value)
        })
        else
        this.setState({
            other:0
        })
    }
    transportation =(e)=>{
        if(e.target.value)
        this.setState({
            transportation:parseInt(e.target.value)
        })
        else
        this.setState({
            transportation:0
        })
    }
    entertainment =(e)=>{
        if(e.target.value)
        this.setState({
            entertainment:parseInt(e.target.value)
        })
        else
        this.setState({
            entertainment:0
        })
    }
    food =(e)=>{
        if(e.target.value)
        this.setState({
            food:parseInt(e.target.value)
        })
        else
        this.setState({
            food:0
        })
    }
    miscellaneous =(e)=>{
        if(e.target.value)
        this.setState({
            miscellaneous:parseInt(e.target.value)
        })
        else
        this.setState({
            miscellaneous:0
        })
    }
    handleClick = e => {

        let inflow={
            employment_gross:this.state.employment_gross,
            less_cpf_contribution:this.state.less_cpf_contribution,
            // less_cpf_contribution:(this.state.employment_gross*2)/10,

            any_other_income:this.state.any_other_income,
            total_monthly_income:(this.state.employment_gross + this.state.any_other_income) -(this.state.less_cpf_contribution)
        }
        let outflow={
            fixedExpenses:{
                insurance_premium_saving:this.state.insurance_premium_saving,
                parental_nafkah_support:this.state.parental_nafkah_support,
                housing_loan_installment:this.state.housing_loan_installment,
                other_loan_installment:this.state.other_loan_installment,
                other:this.state.other
            },
            discretionaryExpenses:{
                transportation:this.state.transportation,
                entertainment:this.state.entertainment,
                food:this.state.food,
                miscellaneous:this.state.miscellaneous,
                total_monthly_expenses:this.state.insurance_premium_saving+
                this.state.parental_nafkah_support+
                this.state.housing_loan_installment+
                this.state.other_loan_installment+
                this.state.other+
                this.state.transportation+
                this.state.entertainment+
                this.state.food+
                this.state.miscellaneous,
                shortfall_surplus:(((this.state.employment_gross + this.state.any_other_income) - (this.state.less_cpf_contribution))-(this.state.insurance_premium_saving+
                    this.state.parental_nafkah_support+
                    this.state.housing_loan_installment+
                    this.state.other_loan_installment+
                    this.state.other+
                    this.state.transportation+
                    this.state.entertainment+
                    this.state.food+
                    this.state.miscellaneous))
            }
        }
        this.props.cashInflow(inflow)
        this.props.cashOutflow(outflow)
        this.props.next()
    }
    componentDidMount(){
        window.scrollTo(0,0)
    }
    render() {
        document.getElementById('cashCircle').style.backgroundColor='#F58023'
        document.getElementById('cash').style.backgroundColor='#F58023'
        document.getElementById('footid').style.height='50px'
        return (
            <div className="main-page-element">
                <h2 className="nameh1">
                To help you create your personalised cashflow statement, 
                and calculate your financial ratios, help me fill this up
                </h2>
                <div>
        <div className="btn">
        <a href="https://my-schedule.timetrade.com/app/td-2524385/workflows/w8w3h/schedule/welcome?wfsid=16a5bdb5-baba97f6-16a5be82-baba97f6-00000002-5psrhv71kgidilce4l1n5vrj8iraghf6&view=full&fs=1" 
        className="meetlink" target="_blank">
        Meet Helmi Hakim Directly
        </a>
       
        <button  className='informationBtn1' data-tip data-for='currentLiabilitiesInfo1'> <b>i</b>
        <ReactTooltip id='currentLiabilitiesInfo1' type='error'>
          <span className=".meethelmi"> I would like to proceed directly<br/> to meet Helmi Hakim for <br/>“Understand Your Money” session.</span>
        </ReactTooltip></button> </div>
    </div>
                <div className='cashFlow'>
                    <div className='cashFlowBar'>
                        <label >Cash Inflow</label>
                    </div>
                    <div className='cashFlowElements'>
                        <div className='cashFlowElement'>
                            <label>Employment Gross</label>
                            <input onBlur={this.employment_gross}></input>
                        </div>
                        <div className='cashFlowElement'>
                            <label>Less CPF Contribution</label>
                            <input type="text" id="cpf" onMouseMove={this.less_cpf_contribution} onBlur={this.less_cpf_contribution} ></input>
                        </div>
                        <div className='cashFlowElement'>
                            <label>Add any other income</label>
                            <input onBlur={this.any_other_income}></input>
                        </div>
                        <div className='cashFlowElement'>
                            <label>Total Monthly Income</label>
                            <label style={{width:'55%',padding:'10px',float:'none'}}>
                                 {(this.state.employment_gross + this.state.any_other_income) -(this.state.less_cpf_contribution) }    
                            </label>   
                        </div>
                    </div>
                    <div className='cashFlowBar'>
                        <label>Cash Outflow</label>
                        
                    </div>
                    <div className='cashFlowElements'>
                        <div className='cashFlowElement' style={{backgroundColor:'rgb(255, 214, 178)'}}>
                            <h3 className="nameh1">Fixed Expenses</h3>
                        </div>
                        <div className='cashFlowElement'>
                            <label>Insurance Premium/savings</label>
                            <input onBlur={this.insurance_premium_saving}></input>
                        </div>
                        <div className='cashFlowElement'>
                            <label>Parental/Nafkah Support</label>
                            <input onBlur={this.parental_nafkah_support}></input><br/>
                        </div>
                        <div className='cashFlowElement'>
                            <label>Housing Loan Instalments (Cash Portion)</label>
                            <input onBlur={this.housing_loan_installment}></input><br/>
                        </div>
                        <div className='cashFlowElement'>
                            <label>Other Loan Instalments</label>
                            <input onBlur={this.other_loan_installment}></input><br/>
                        </div>
                        <div className='cashFlowElement'>
                            <label>Others</label>
                            <input onBlur={this.other}></input><br/>
                        </div>
                        <div className='cashFlowElement' style={{backgroundColor:'rgb(255, 214, 178)'}}>
                            <h3 className="nameh1">Discretionary Expenses</h3>
                        </div>
                        <div className='cashFlowElement'>
                            <label >Transportation</label>
                            <input onBlur={this.transportation}></input>
                        </div>
                        <div className='cashFlowElement'>
                            <label>Entertainment</label>
                            <input onBlur={this.entertainment}></input>
                        </div>
                        <div className='cashFlowElement'>
                            <label>Food</label>
                            <input onBlur={this.food}></input>
                        </div>
                        <div className='cashFlowElement'>
                            <label>Miscellaneous</label>
                            <input onBlur={this.miscellaneous}></input>
                        </div>
                        <div className='cashFlowElement' style={{backgroundColor:'rgb(255, 214, 178)'}}>
                            <label>Total monthly expenses</label>
                            <label style={{width:'55%',padding:'10px',float:'none'}}>
                                {
                                    this.state.insurance_premium_saving+
                                    this.state.parental_nafkah_support+
                                    this.state.housing_loan_installment+
                                    this.state.other_loan_installment+
                                    this.state.other+
                                    this.state.transportation+
                                    this.state.entertainment+
                                    this.state.food+
                                    this.state.miscellaneous
                                }
                            </label>
                        </div>
                    </div>
                    <div className='cashFlowBar'>
                        <label >Cash Inflow</label>
                    </div>
                    <div className='cashFlowElements'>
                        <div className='cashFlowElement'>
                            <label>Shortfall/Surplus</label>
                            <label style={{width:'55%',padding:'10px',float:'none'}}>
                            {(((this.state.employment_gross + this.state.any_other_income) - ( this.state.less_cpf_contribution
                                )
                                )-(this.state.insurance_premium_saving+
                                this.state.parental_nafkah_support+
                                this.state.housing_loan_installment+
                                this.state.other_loan_installment+
                                this.state.other+
                                this.state.transportation+
                                this.state.entertainment+
                                this.state.food+
                                this.state.miscellaneous))}
                            </label>
                        </div>
                    </div>
                </div>
                <br></br><br></br>
                <div>
                    <button onClick={this.handleClick}>Next</button>
                </div>
            </div>
        )
    }
}

export default Cashflow
