import React, { Component } from 'react'
import Name from './Name'
import DOBGender from './DOBGender'
import Landing from '../../Landing'
import Cashflow from './Cashflow'
import Cashflow2 from './Cashflow2'
import Thankyou from './Thankyou'
import unirest from 'unirest'

export class Login extends Component {
    constructor(){
        super()
        this.state={
            page:0,
            name:"",
            email:"",
            phone:"",
            dateDay:"",
            dateMonth:"",
            dateYear:"",
            cashInflow:{},
            cashOutFlow:{},
            assets:{},
            liabilities:{},
            netWorth:{}
        }
    }
    family=[]
    changeSideBarColor=()=>{
        document.getElementById('smokeCircle').style.backgroundColor='#F58023'
        document.getElementById('aboutyouCircle').style.backgroundColor='#F58023'
    }
    goNext = () =>{
        this.setState({
            page:this.state.page+1
        })
    }

    get_token=()=>{
        return new Promise(resolve=>{
            var req = unirest("POST", "https://auth.aweber.com/oauth2/token");
            req.headers({
            // "postman-token": "812cbb02-f8b9-129c-1b33-79b7f534d0b6",
            // "cache-control": "no-cache",
            "authorization": "Basic VDIwd1NVMjhlTVRGNnJYODFNZWFxNEJidzRLMFN2ZTM6dEYwbk1xQnZ6U2kzTmZrS3RwQjB5SGl0SktPcmF6WlQ=",
            "content-type": "application/x-www-form-urlencoded"
            });
            req.form({
            "grant_type": "refresh_token",
            "refresh_token": "d8IhJV86KxIDKQ3iOUoxmtyVBqkSAWYb"
            });
            req.end(function (res) {
                console.log("success")
            if (res.error) 
            throw new Error(res.error);
            // console.log("eror")
            // console.log(res.body);
            // console.log(res.body.access_token);
            resolve(res.body.access_token);
            });
        });
    }
    savedata=(name,email,phone)=>{
        return this.get_token().then(res=>{
            //  let res = "bearer U9UDYJBcWO99LeunEcXRevamDpBgZlwz";
            //  let auth = ;
            // console.log(res);
            // console.log(name,email,phone)
            var req = unirest("POST", "https://api.aweber.com/1.0/accounts/929919/lists/5481929/subscribers");
            req.headers({
            "content-type": "application/json",
            "authorization": `bearer ${res}`
            });
            req.type("json");
            req.send({
            "email": `${email}`,
            "name": `${name}`,
            "phone":`${phone}`
            });
            req.end(function (res) {
            if (res.error)
            
            // throw new Error(res.error);
            console.log(res.body);
            });
        });
}
    setName = (value) =>{
        this.setState({
            name:value
        })
        
    }
    setEmail = (value) => {
        this.setState({
            email:value
        })
    }
    setPhone = (value) => {
        this.setState({
            phone:value
        })
    }
    setDay = (value)=>{
        this.setState({
            dateDay:value
        })
    }
    setMonth = (value)=>{
        this.setState({
            dateMonth:value
        })
    }
    setYear = (value)=>{
        this.setState({
            dateYear:value
        })
    }
    setCashInflow = value => {
        this.setState({
            cashInflow:value
        })
    }
    setCashOutflow = value => {
        this.setState({
            cashOutFlow:value
        })
    }
    setAssets = value => {
        this.setState({
            assets:value
        })
    }
    setLiabilities = value => {
        this.setState({
            liabilities:value
        })
    }
    setNetWorth = value => {
        this.setState({
            netWorth:value
        })
    }
    render() {
        if(this.state.page === 0)
        return(
            <React.Fragment>
                {console.log(this.state.page)}
                <Landing next={this.goNext}/>
            </React.Fragment>
        )
        if(this.state.page === 1)
        return (
            <React.Fragment>
                   <Name next={this.goNext} 
                   name={this.setName} 
                   email = {this.setEmail} 
                   phone={this.setPhone}/>
                  
                
            </React.Fragment>
        )
        if(this.state.page === 2){
        this.savedata(this.state.name,this.state.email,this.state.phone)
        return (
            
            <React.Fragment>
                

                   <DOBGender next={this.goNext} 
                   day={this.setDay}
                   month ={this.setMonth}
                   year = {this.setYear} /> 
                
                
            </React.Fragment>
        )}
        if (this.state.page === 3)
        return(
            <React.Fragment>
                <Cashflow next={this.goNext}
                cashInflow={this.setCashInflow} 
                cashOutflow={this.setCashOutflow}/>
            </React.Fragment>

        )
        if (this.state.page === 4)
        return(
            <React.Fragment>
                <Cashflow2 next={this.goNext} 
                assets={this.setAssets} 
                liabilities={this.setLiabilities} 
                netWorth={this.setNetWorth} />
            </React.Fragment>

        )
        if (this.state.page === 5)
        return(
            <React.Fragment>
                <Thankyou 
                object={this.state}/>
            </React.Fragment>

        )
    }
}

export default Login
