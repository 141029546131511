import React from "react";
import {FaLock , FaPhone} from 'react-icons/fa'
import Logo from './../LogoHalal/PNG/logo-color.png'

import  './../App.css'

function Header() {
  
  return (
  <div id='Header'>
    <div id='headerElement1'>
      <img src ={Logo} ></img>
    </div>
    
    <div id='headerElement2'>
      <div >
        <FaPhone className="headerfaphone" />
        <a className ='headerLink' target="_blank" href='https://api.whatsapp.com/send?phone=6596520134&text=Salam%20Financial%20Consultant%2c%20Helmi%20Hakim.%20I%27m%20interested%20to%20get%20the%20Halal%20Financial%20Plan&source=&data=&app_absent=' style={{lineHeight:"64px"}}> Contact</a>
      </div>
      
      <div>
        <button className= 'headerBTN'>
          <FaLock/>Save Progress
        </button>
      </div>
      <div className="headerfafaiconmenu">
     <i className="fa fa-bars" aria-hidden="true"></i>
     </div>
    
    </div>
    
  </div>
  );
}
export default Header;
