
import './App.css';
import Login from './Components/LoginForm/Login'
import Header from './Components/Header';
import { Route, BrowserRouter, Switch} from "react-router-dom";
import DOBGender from './Components/LoginForm/DOBGender'
import Sidebar from './Components/Sidebar';
import Buttommenu from './Components/buttommenu'
import React, { Component } from 'react'
import Cashflow2 from './Components/LoginForm/Cashflow2';
import Cashflow from './Components/LoginForm/Cashflow';
import afterlanding from './Components/LoginForm/afterlanding'
import LoginPage from './Components/LoginForm/LoginPage'

export class App extends Component {
  render() {
    return (
      <BrowserRouter >
      {/* <div className="App">
        <Header />
        <br/>
        <Buttommenu/>
        <div className= 'container'>
          <Sidebar/>
          <div className='main-page'>
              <Login/>
            <div  className='main-page-element' id='footid' style={{
            backgroundColor:'white',
            height:'50px',
            marginBottom:0,
            minHeight:'0'
            }}></div>
          </div>
          
        </div>
        
      </div> */}
      <Switch>
          <Route exact path="/Login" component={Login} />
          
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/afterlanding" component={afterlanding} />
          <Route exact path="/dobGender" component={DOBGender} />
      </Switch>
    </BrowserRouter>
    );
  }
}


export default App;
