import React, { Component } from 'react'

import  './../../App.css'

export class Name extends Component {
    constructor(props){
        super(props)
        this.state={
            visible:false,
            vis:'0',
            nameFill:false,
            emailFill:false,
            phoneFill:false,
            btnDisable:true
        }
    }
    name= false
    email=false
    phone=false
    btnDisable=true
    componentDidMount() {
      window.scrollTo(0, 0);
    }

    
    
    render() {
        document.getElementById('nameCircle').style.backgroundColor='#F5802C'
        document.getElementById('name').style.backgroundColor='#F5802C'
        // console.log("this.props",this.props.next)
        const nameRegex=/^[a-zA-Z.\s]{2,30}$/;
        const emailRegex=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const phoneRegex=/^[0-9+]{8,15}$/;
        const showVisibility=()=>{
            this.setState({
                vis:'100'
            })
        }
        const hideVisibility=()=>{
            this.setState({
                vis:'0'
            })
        }
        const validateName=(event)=>{
            this.props.name(event.target.value)
            if(event.target.value.match(nameRegex)){ 
            this.setState({nameFill:true})
            this.name=true
            }
            else
             {
                 this.setState({nameFill:false, btnDisable:true})
                  this.name=false 
                  this.btnDisable=true
             }
            enabledisableBotton()
        }
        const validatePhone=(event)=>{
            this.props.phone(event.target.value)
            // console.log("event.target.value",event.target.value)
            if(event.target.value.match(phoneRegex)){ 
                // console.log("event.target.valuejhg",event.target.value)
            this.setState({phoneFill:true})
            this.phone=true
            }
            else
             {
                //  this.setState({nameFill:false, btnDisable:true})
                  this.phone=false 
                  this.btnDisable=true
             }
            enabledisableBotton()
        }
        
        const validateEmail=(event)=>{
            this.props.email(event.target.value)
            if(event.target.value.match(emailRegex)){
             this.setState({emailFill:true})
            this.email=true
            }
           
            else 
            {
                this.setState({emailFill:false, btnDisable:true})
                this.email=false
                this.btnDisable=true
            }
            enabledisableBotton()
            
        }

        const enabledisableBotton=()=>{
           
            // if (this.state.nameFill && this.state.emailFill)
            if(this.name && this.email && this.phone)
            {
            // this.setState({btnDisable:false})
            this.btnDisable=false
            }
            
            else
            {
            // this.setState({btnDisable:true})
            this.btnDisable=true
            }
            // console.log("name",this.name)
            // console.log("email",this.email)
            // console.log("phone",this.phone)
            // console.log("btnDisable",this.btnDisable)
        }


        
        return (
            <div className='main-page-element' style={{position:'relative'}}>
                <h1 className="nameh1">Mabruk!</h1>
                <h1 className="nameh1">Congratulations for taking the first step to take charge of your personal finance!</h1>
                <p className="namep">What is your name? How can I address you?</p>
               
                <input type="text" className="nameinput" name="name" pattern="[A-Za-z]{3}" onChange={validateName}  required=""></input>
                
                <br/><br/>
                <p className="namep">and email address? (in case we disconnect along the way)</p>
                <input type="email" className="nameinput" name="email" onChange={validateEmail}  required=""></input>
                <br/><br/>
                <p className="namep">and Phone Number? (in case we disconnect along the way)</p>
                <input type="text" className="nameinput" name="phone" pattern="[0-9]{10}" onChange={validatePhone}  required=""></input>
                
                <br/><br/>
                <button onClick={this.props.next} disabled={this.btnDisable} >Next</button>
                
                <div style={{height:'120px'}}></div>
                <div className="namediv" style={{
                    opacity:this.state.vis
                }} id='helpmsg'>
                Salam. WhatsApp Islamic Financial Consultant, Helmi Hakim at 96520134 should you need any assistance.🙂👍
                    <button onClick={hideVisibility} className="namebtn">X CLOSE</button>
                </div>
                <button className="namehelpbtn" id='helpbutton' onMouseMove={showVisibility} onMouseOver={showVisibility} onMouseEnter={showVisibility}>
                    Can You Assist Me?
                </button>
            </div>


 )
    }
}

export default Name

