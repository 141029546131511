import React, { Component } from 'react'

export class Sidebar extends Component {
    constructor(){
        super()
        this.state={
            hello:'hey'
        }
    }
    hello=()=>{}
    render() {
        return (
            <div id='sidebar'>
                <div className='adviser-tracker-container'>
                    <div name= 'AboutYou' className='adviser-catogery-tracker'>
                        <div>
                            <span className='adviser-tracker-value'>MY JOURNEY</span>
                            <div id="aboutyouCircle" style={{backgroundColor:"#F28D46"}} className='adviser-tracker-circle-main' ></div>
                        </div>
                        <div>
                            <span className='adviser-tracker-value' id='sidebarName'>Name</span>
                            <div className='adviser-tracker-circle' id='name' style={{height: "13px",
                                width:"13px"}}></div>
                        </div>
                        <div>
                            <span className='adviser-tracker-value'>Age</span>
                            <div id="ageGender" className='adviser-tracker-circle'></div>
                        </div>
                        <div>
                            <span className='adviser-tracker-value'>Cashflow</span>
                            <div id="cash" className='adviser-tracker-circle'></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Sidebar

