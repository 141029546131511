import React, { Component } from 'react'

export class Report extends Component {
    constructor(props){
        super(props)

    }
    render() {
        return (
            <div>
                <div>
                    <img></img>
                </div>
                <div>
                    <label> Name = {this.props.person.name} </label>
                    <label> Email = {this.props.person.email} </label>
                    <label> Date of Birth = {`${this.props.person.dateDay}//${this.props.person.dateMonth}//${this.props.person.dateYear}`} </label>
                </div>
                <div>
                    <label> Basic Liquidity Ratio = {this.props.state.basic_liquidity_ratio}</label><br/>
                    <label> Liquid Assets to Networth Ratio = {this.props.state.liquid_assets_to_netWorth_ratio} </label>
                    <label> Savings Ratio = {this.props.state.savings_ratio} </label>
                    <label> Debt to Assets Ratio = {this.props.state.debt_to_assets_ratio}</label>
                    <label> Solvency Ratio = {this.props.state.solvency_ratio} </label>
                    <label> Debt Service Ratio = {this.props.state.debt_service_ratio} </label>
                    <label> Non-Mortage Service Ratio {this.props.state.non_mortage_service_ratio} </label>
                    <label> Net Investment Assets to Networth Ratio = {this.props.state.net_investement_assets_to_netWorth_ratio} </label> 
                </div>
            </div>
        )
    }
}

export default Report

