import React from "react";
import {FaLock , FaPhone} from 'react-icons/fa'
import Logo from './../LogoHalal/PNG/logo-color.png'

// import  './../App.css'
import './bottommenu.css'

function Header() {
  
  return (
  <div id='menu'>
  <div class="topnav">
  <div id="dashed">
    <a href="#">MY JOURNEY</a>
    <a href="#">Name</a>
    <a href="#">Age</a>
     <a href="#">CashFlow</a>
     </div>
     <div class="circle" id="aboutyouCircle" style={{marginLeft:"21%", marginTop:"-9px", backgroundColor:"#F28D46"}}></div>
     <div class="circle" id="nameCircle" style={{marginLeft:"45%", marginTop:"-14px"}}></div>
     <div class="circle" id="ageGenderCircle" style={{marginLeft:"63%", marginTop:"-16px"}}></div>
     <div class="circle" id="cashCircle" style={{marginLeft:"85%", marginTop:"-14px"}}></div>
  </div>

    </div>
    
    
  );
}
export default Header;
