import React, { Component } from 'react'
import {passcode} from './Components/LoginForm/config'
export class Landing extends Component {

    Login =(props) =>{
        console.clear();
        // console.log("Login Click")
        // console.log("props",this.props.history)
       
        var txt;
        // var passcode="amit"
        var person = prompt("Please enter your Passcode:",);
      
        if (person === passcode) {
            // console.log("props",this.props.history)
            // console.log("props",this.props.next())
            this.props.next()
            
        //   console.log("md5",md5(md5(md5(passcode))));
        //   localStorage.setItem("token", passcode)

        //   this.props.history.push('/Login')
        // txt = "Hello " + person + "! How are you today?";
          
        } else {
          alert("Please enter your correct PassCode");
        // txt = "Please enter your correct PassCode";
          
        }
    }

    render() {
        // document.getElementById('aboutyouCircle').style.backgroundColor='#F58023'
        console.log("this.",this.props.next)
        
        // console.clear();
        return (
            <div className="main-page-element" style={{textAlign:'left'}}>
                <div className="landingdiv">
                    <div className="homeH1text">
                        <h1>
                        Assalamualaikum. Ahlan Wa Sahlan. Selamat Datang!
                        </h1>
                        <h2>
                        Just a simple clause before we proceed
                        </h2>
                    </div>
                    <div className="homemiddletext">
                    <div className="homemiddle">
                    <p style={{textAlign:"left"}}>
                    HalalFinancialPlan.sg is committed to maintaining your confidence and trust. 
                    </p>
                    <p style={{textAlign:"left"}}>
                    We understand that maintaining the confidentiality of your personal and financial 
                    information is of the utmost importance to you. We therefore wish to assure you that 
                    all information collected will only be used for the purpose of analysing your financial 
                    needs and providing recommendations based on that analysis, and will be treated in strict 
                    confidence. We will not release your information to any third party.
                    </p>
                    <p style={{textAlign:"left"}}>
                    HalalFInancialPlan.sg must have sufficient information before making a suitable recommendation. 
                    The information that you provide on your financial goals, financial situation and your 
                    particular needs will be the basis on which financial advice and recommendation will be given. 
                    </p>
                    <p style={{textAlign:"left"}}>
                    The recommendations made for you may not be appropriate in the event of a partial or 
                    inaccurate completion of this document.
                    </p>
                    <p style={{textAlign:"left"}}>
                    Please be advised that all analysis conducted in respect of your needs in life protection, 
                    disability income protection, retirement planning, education planning and wealth management 
                    are for your reference only. 
                    </p>
                    <p style={{textAlign:"left"}}>
                    Formulations of the analysis are estimates only and based on hypothetical assumptions and
                    information provided by you.
                    </p>
                    <p style={{textAlign:"left"}}>
                    No guarantee or representations can be made that the formulations or assumptions are full 
                    and complete at present or in the future. The analysis merely provides an estimate of your 
                    personal needs based on calculations. It shall not constitute a substitute for professional 
                    advice.
                    </p>
                    <h5 className="landingh5">
                    Personal data collection statement
                    </h5>
                    <p style={{textAlign:"left"}}>
                    HalalFinancialPlan.sg recognises its obligations under the Personal Data Protection Act 
                    2012 (PDPA) which include the collection, use and disclosure of personal data for the 
                    purpose for which an individual has given consent to. The personal data collected by  
                   <a href="https://halalfinancialplan.sg/" style={{color:"#4abc96", textDecoration:"none"}}> halalfinancialplan.sg</a> includes all personal data provided in this form, or in any document
                    provided, or to be provided to us by you or your insured persons or from other sources, forthe purpose of this
                    insurance application or transaction. It includes all personal data for us to evaluate or 
                    administer this application ortransaction. 

                    </p>
                    <h5 className="landingh5">
                    1. Purpose of collection
                    </h5>
                    <p >
                    
                    We may collect and use the personal data to:
                    </p>
                    <p style={{textAlign:"left"}}> (a) communicate on purposes relating to an 
                    application; <br/>(b) determine and verify your credit worthiness  for the financial and insurance products you apply for; <br/>(c) provide financial advice for product recommendation based on your 
                    financial needs analysis; <br/>(d) provide ongoing services and respond to your inquiries or 
                    instructions; <br/>(e) coach employees and monitor for quality assurance; and <br/>(f) comply with all
                    applicable laws, including reporting to regulatory and industry entities.
                    </p>
                    <h5 className="landingh5">
                    2. Disclosure of personal data
                    </h5>
                    <p style={{textAlign:"left"}}>
                    We may disclose personal data belonging to you and your insured persons for the purposes set 
                    out in section 1 above to:
                    </p>
                    </div>
                    <ol>
                        <li>Your representatives;</li> 
                        <li>Local or overseas service providers to provide us with services such as printing, 
                            mail distribution, data storage, data entry; and </li>
                        <li>Regulators, law enforcement and government agencies.</li>
                    </ol>
                    </div>
                    <br/>
                    <div className="letsbegin">
                        <button onClick={this.props.next}>Lets Begin!</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Landing
