import React, { Component } from 'react'
import nodemailer from 'nodemailer'
import axios from 'axios'
import Report from './Report'
import Swal from "sweetalert2";

export class Thankyou extends Component {
    constructor(props){
        super(props)
        // console.log("props value",props.object)
        // console.log("props kjkjvalue",props)
        this.state={
            basic_liquidity_ratio:
            (
                this.props.object.assets.cash_near_cash.cash_bank_deposites / this.props.object.cashOutFlow.discretionaryExpenses.total_monthly_expenses
            ),
            
            liquid_assets_to_netWorth_ratio:
            (
                this.props.object.assets.cash_near_cash.cash_bank_deposites * 100 / this.props.object.netWorth.networth
            ),
            savings_ratio:
            (
                this.props.object.cashOutFlow.fixedExpenses.insurance_premium_saving * 100 / this.props.object.cashInflow.employment_gross
            ),
            debt_to_assets_ratio:
            (
                this.props.object.liabilities.total_liabilities * 100 / this.props.object.assets.total_assests
            ),
            solvency_ratio:
            (
                this.props.object.netWorth.networth * 100 / this.props.object.assets.total_assests
            ),
            debt_service_ratio:
            (
                (
                    (this.props.object.cashOutFlow.fixedExpenses.housing_loan_installment
                        + this.props.object.cashOutFlow.fixedExpenses.other_loan_installment
                    ) * 100
                ) / this.props.object.cashInflow.total_monthly_income
            ),
            non_mortage_service_ratio:
            (
                this.props.object.cashOutFlow.fixedExpenses.other_loan_installment * 100 / this.props.object.cashInflow.total_monthly_income
            ),
            net_investement_assets_to_netWorth_ratio: 
            (
                this.props.object.assets.invested_assets.total_invested_assets * 100 / this.props.object.netWorth.networth
            )

        }
    }
    handleMailingData = async () =>{
        const templateId = 'test_email';

        this.sendFeedback(templateId,
            {
                message_html: <html><head>test html</head><body>its working</body></html>,
                from_name: 'Amit', 
                reply_to: 'amit.kushwaha@axlewebtech.com'
            })
        
    }

    sendFeedback (templateId, variables) {
        window.emailjs.send(
          'gmail', templateId,
          variables
          ).then(res => {
            // console.log('Email successfully sent!')
          })
          // Handle errors here however you like, or use a React error boundary
          .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
      }


    mail=()=>{
        // console.log('mail functioned called')
        var transporter = nodemailer.createTransport({
            service: 'gmail',
            auth: {
              user: 'skelli19340@gmail.com',
              pass: 'gcjzdyociyacyxyv'
            }
          });
          
          var mailOptions = {
            from: 'skelli19340@gmail.com',
            to: 'keshari.ankit18@gmail.com',
            subject: 'Sending Email using Node.js',
            text: 'That was easy!'
          };
          
          transporter.sendMail(mailOptions, function(error, info){
            if (error) {
            //   console.log(error);
            } else {
            //   console.log('Email sent: ' + info.response);
            }
          });
    }
    
    componentDidMount(){
        window.scrollTo(0,0)
    }
    // codehtml = <html>
    //                 <body>
    //                 <div>
    //                 <div>
    //                     <img></img>
    //                 </div>
    //                 <div>
    //                     <label> Nmae = {this.props.object.name} </label>
    //                     <label> Email = {this.props.object.email} </label>
    //                     <label> Date of Birth = {`${this.props.object.dateDay}//${this.props.object.dateMonth}//${this.props.object.dateYear}`} </label>
    //                 </div>
    //                 <div>
    //                     <label> Basic Liquidity Ratio = {this.state.basic_liquidity_ratio}</label><br/>
    //                     <label> Liquid Assets to Networth Ratio = {this.state.liquid_assets_to_netWorth_ratio} </label>
    //                     <label> Savings Ratio = {this.state.savings_ratio} </label>
    //                     <label> Debt to Assets Ratio = {this.state.debt_to_assets_ratio}</label>
    //                     <label> Solvency Ratio = {this.state.solvency_ratio} </label>
    //                     <label> Debt Service Ratio = {this.state.debt_service_ratio} </label>
    //                     <label> Non-Mortage Service Ratio = {this.state.non_mortage_service_ratio} </label>
    //                     <label> Net Investment Assets to Networth Ratio = {this.state.net_investement_assets_to_netWorth_ratio} </label> 
    //                 </div>
    //            </div>
    //                 </body>    
    //             </html>
    render() {
        // console.log("phone",this.props.object.phone)
        // console.log("employment_gross",this.props.object.cashInflow.employment_gross)
        // console.log("less_cpf_contribution",this.props.object.cashInflow.less_cpf_contribution)
        // console.log("any_other_income",this.props.object.cashInflow.any_other_income)
        // console.log("total_monthly_income",this.props.object.cashInflow.total_monthly_income)
        // // console.log("fixedExpenses",this.props.object.cashOutFlow.fixedExpenses.fixedExpenses)

        // console.log("insurance_premium_saving",this.props.object.cashOutFlow.fixedExpenses.insurance_premium_saving)
        // console.log("parental_nafkah_support",this.props.object.cashOutFlow.fixedExpenses.parental_nafkah_support)
        // console.log("housing_loan_installment",this.props.object.cashOutFlow.fixedExpenses.housing_loan_installment)
        // console.log("other_loan_installment",this.props.object.cashOutFlow.fixedExpenses.other_loan_installment)

        // console.log("other",this.props.object.cashOutFlow.fixedExpenses.other)

        // console.log("transportation",this.props.object.cashOutFlow.discretionaryExpenses.transportation)
        // console.log("entertainment",this.props.object.cashOutFlow.discretionaryExpenses.entertainment)
        // console.log("food",this.props.object.cashOutFlow.discretionaryExpenses.food)
        // console.log("miscellaneous",this.props.object.cashOutFlow.discretionaryExpenses.miscellaneous)
        // console.log("total_monthly_expenses",this.props.object.cashOutFlow.discretionaryExpenses.total_monthly_expenses)
        // console.log("shortfall_surplus",this.props.object.cashOutFlow.discretionaryExpenses.shortfall_surplus)

        // console.log("cash_bank_deposites",this.props.object.assets.cash_near_cash.cash_bank_deposites)
        // console.log("cash_others",this.props.object.assets.cash_near_cash.cash_others)
        // console.log("total_cash_near_cash",this.props.object.assets.cash_near_cash.total_cash_near_cash)

        // console.log("cpf_ordinary_account",this.props.object.assets.invested_assets.cpf_ordinary_account)
        // console.log("cpf_special_account",this.props.object.assets.invested_assets.cpf_special_account)
        // console.log("cpf_medisave_account",this.props.object.assets.invested_assets.cpf_medisave_account)
        // console.log("investment",this.props.object.assets.invested_assets.investment)
        // console.log("investment_cash_value",this.props.object.assets.invested_assets.investment_cash_value)
        // console.log("invested_others",this.props.object.assets.invested_assets.invested_others)
        // console.log("total_invested_assets",this.props.object.assets.invested_assets.total_invested_assets)

        // console.log("family_home",this.props.object.assets.personal_use_assets.family_home)
        // console.log("moter_vehicles",this.props.object.assets.personal_use_assets.moter_vehicles)
        // console.log("total_personal_use_assets",this.props.object.assets.personal_use_assets.total_personal_use_assets)

        // console.log("total_assests",this.props.object.assets.total_assests)
        
        // console.log("credit_card_balances",this.props.object.liabilities.current_liabilities.credit_card_balances)
        // console.log("current_liablilities_others",this.props.object.liabilities.current_liabilities.current_liablilities_others)
        // console.log("total_current_liabilities",this.props.object.liabilities.current_liabilities.total_current_liabilities)
        
        // console.log("car_hire_purchase",this.props.object.liabilities.long_term_liabilities.car_hire_purchase)
        // console.log("mortgage_loan_balance",this.props.object.liabilities.long_term_liabilities.mortgage_loan_balance)
        // console.log("long_term_liabilities_others",this.props.object.liabilities.long_term_liabilities.long_term_liabilities_others)
        // console.log("total_long_liabilities",this.props.object.liabilities.long_term_liabilities.total_long_liabilities)

        // console.log("total_liabilities",this.props.object.liabilities.total_liabilities)


        // console.log("networth",this.props.object.netWorth.networth)
        // console.log("total_networth_and_liabilities",this.props.object.netWorth.total_networth_and_liabilities)
        if(this.props.object.cashInflow.total_monthly_income>0)
            {
        axios.post(`https://axlewebtech.com/scripts/mailing/helmi_financial/mailer2.php?name=${this.props.object.name}
        &email=${this.props.object.email}
        &phone=${this.props.object.phone}
        &dateDay=${this.props.object.dateDay}
        &dateMonth=${this.props.object.dateMonth}
        &dateYear=${this.props.object.dateYear}
        &basic_liquidity_ratio=${this.state.basic_liquidity_ratio}
        &liquid_assets_to_netWorth_ratio=${this.state.liquid_assets_to_netWorth_ratio}
        &savings_ratio=${this.state.savings_ratio}
        &debt_to_assets_ratio=${this.state.debt_to_assets_ratio}
        &solvency_ratio=${this.state.solvency_ratio}
        &debt_service_ratio=${this.state.debt_service_ratio}
        &non_mortage_service_ratio=${this.state.non_mortage_service_ratio}
        &net_investement_assets_to_netWorth_ratio=${this.state.net_investement_assets_to_netWorth_ratio}
        &employment_gross=${this.props.object.cashInflow.employment_gross}
        &less_cpf_contribution=${this.props.object.cashInflow.less_cpf_contribution}
        &any_other_income=${this.props.object.cashInflow.any_other_income}
        &total_monthly_income=${this.props.object.cashInflow.total_monthly_income}
        &insurance_premium_saving=${this.props.object.cashOutFlow.fixedExpenses.insurance_premium_saving}
        &parental_nafkah_support=${this.props.object.cashOutFlow.fixedExpenses.parental_nafkah_support}
        &housing_loan_installment=${this.props.object.cashOutFlow.fixedExpenses.housing_loan_installment}
        &other_loan_installment=${this.props.object.cashOutFlow.fixedExpenses.other_loan_installment}
        &other=${this.props.object.cashOutFlow.fixedExpenses.other}
        &transportation=${this.props.object.cashOutFlow.discretionaryExpenses.transportation}
        &entertainment=${this.props.object.cashOutFlow.discretionaryExpenses.entertainment}
        &food=${this.props.object.cashOutFlow.discretionaryExpenses.food}
        &miscellaneous=${this.props.object.cashOutFlow.discretionaryExpenses.miscellaneous}
        &total_monthly_expenses=${this.props.object.cashOutFlow.discretionaryExpenses.total_monthly_expenses}
        &shortfall_surplus=${this.props.object.cashOutFlow.discretionaryExpenses.shortfall_surplus}
        &cash_bank_deposites=${this.props.object.assets.cash_near_cash.cash_bank_deposites}
        &cash_others=${this.props.object.assets.cash_near_cash.cash_others}
        &total_cash_near_cash=${this.props.object.assets.cash_near_cash.total_cash_near_cash}
        &cpf_ordinary_account=${this.props.object.assets.invested_assets.cpf_ordinary_account}
        &cpf_special_account=${this.props.object.assets.invested_assets.cpf_special_account}
        &cpf_medisave_account=${this.props.object.assets.invested_assets.cpf_medisave_account}
        &investment=${this.props.object.assets.invested_assets.investment}
        &investment_cash_value=${this.props.object.assets.invested_assets.investment_cash_value}
        &invested_others=${this.props.object.assets.invested_assets.invested_others}
        &total_invested_assets=${this.props.object.assets.invested_assets.total_invested_assets}
        &family_home=${this.props.object.assets.personal_use_assets.family_home}
        &moter_vehicles=${this.props.object.assets.personal_use_assets.moter_vehicles}
        &total_personal_use_assets=${this.props.object.assets.personal_use_assets.total_personal_use_assets}
        &total_assests=${this.props.object.assets.total_assests}
        &credit_card_balances=${this.props.object.liabilities.current_liabilities.credit_card_balances}
        &current_liablilities_others=${this.props.object.liabilities.current_liabilities.current_liablilities_others}
        &total_current_liabilities=${this.props.object.liabilities.current_liabilities.total_current_liabilities}
        &car_hire_purchase=${this.props.object.liabilities.long_term_liabilities.car_hire_purchase}
        &mortgage_loan_balance=${this.props.object.liabilities.long_term_liabilities.mortgage_loan_balance}
        &long_term_liabilities_others=${this.props.object.liabilities.long_term_liabilities.long_term_liabilities_others}
        &total_long_liabilities=${this.props.object.liabilities.long_term_liabilities.total_long_liabilities}
        &total_liabilities=${this.props.object.liabilities.total_liabilities}
        &networth=${this.props.object.netWorth.networth}
        &total_networth_and_liabilities=${this.props.object.netWorth.total_networth_and_liabilities}`)
        // this.handleMailingData()
        // console.log('ratios: ',this.state)
        return (
            <div className='main-page-element'>
                <div className="thanksdiv">
                    <p className="thanksp">
                        Awesome! <a href="https://my.timetrade.com/book/W8W3H" className="thanksmessage" style={{color:"#4abc96", textDecoration:"none"}}>Click here</a> to schedule “Understand Your Numbers” session.
                        
                    </p>
                    <p className="thanksp">
                        Financial Consultant, Helmi Hakim will run through with you on your 
                        current financial situation now.
                    </p>
                    <p className="thanksp">
                        And how you can bring your finance to a better position. Insya’Allah. 😊
                    </p>
                    <h1 className="thanksbottom">
                        Thank You.
                    </h1>
                </div>
            
            </div>
        )
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title:'Your Data is not submited',
                    text:'Please Check Your Internet Connection',
                })
                .then((result)=>{
                    window.location.reload();
                })
                
            }
       
    }
}

export default Thankyou

